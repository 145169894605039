import { defineStore } from 'pinia';
import { useUserStore } from '../stores/user';

const photoFormats = ['png', 'jpg', 'jpeg', 'tif', 'tiff'];
const videoFormats = ['mov', 'mp4', 'm4v'];
const documentsFormats = ['doc', 'docx', 'txt', 'pdf', ...photoFormats];
export const tagsDenotationObj = {
  passport_2_3: {
    header: 'passport',
    name: 'Страницы паспорта основные',
    description: 'Необходимо загрузить страницы №2, №3',
    formats: photoFormats,
    instruction: [
      'Разложите паспорт на плоскую поверхность так, чтобы все четыре угла были видны в кадре.',
      'Убедитесь, что освещение хорошее и нет бликов на паспорте.',
      'Сфотографируйте паспорт так, чтобы в кадре были видны все его углы, а информация с паспорта легко считывалась.',
      'После этого загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    links: ['https://api.denginadom.ru/v1/file/1724416248078_23_2.jpg'],
    event_name: 'files_passport_2_3',
  },
  passport_4_5_6: {
    header: 'passport',
    name: 'Страницы паспорта прописка',
    description: 'Необходимо загрузить страницы №4, №5, №6',
    formats: photoFormats,
    instruction: [
      'Разложите паспорт на плоскую поверхность так, чтобы все четыре угла были видны в кадре.',
      'Убедитесь, что освещение хорошее и нет бликов на паспорте.',
      'Сфотографируйте паспорт так, чтобы в кадре были видны все его углы, а информация с паспорта легко считывалась.',
      'После этого загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_passport_4_5_6',
  },
  passport_12_13: {
    header: 'passport',
    name: 'Паспорт 12,13 стр',
    description: 'Паспорт',
    formats: photoFormats,
    instruction: [
      'Разложите паспорт на плоскую поверхность так, чтобы все четыре угла были видны в кадре.',
      'Убедитесь, что освещение хорошее и нет бликов на паспорте.',
      'Сфотографируйте паспорт так, чтобы в кадре были видны все его углы, а информация с паспорта легко считывалась.',
      'После этого загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_passport_12_13',
  },
  passport_14_15: {
    header: 'passport',
    name: 'Страницы паспорта о семейном положении',
    description: 'Паспорт семейное положение стр 14 15',
    formats: photoFormats,
    instruction: [
      'Разложите паспорт на плоскую поверхность так, чтобы все четыре угла были видны в кадре.',
      'Убедитесь, что освещение хорошее и нет бликов на паспорте.',
      'Сфотографируйте паспорт так, чтобы в кадре были видны все его углы, а информация с паспорта легко считывалась.',
      'После этого загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_passport_14_15',
  },
  passport_16_17: {
    header: 'passport',
    name: 'Паспорт 16-17 стр',
    description: 'Паспорт',
    formats: photoFormats,
    instruction: [
      'Разложите паспорт на плоскую поверхность так, чтобы все четыре угла были видны в кадре.',
      'Убедитесь, что освещение хорошее и нет бликов на паспорте.',
      'Сфотографируйте паспорт так, чтобы в кадре были видны все его углы, а информация с паспорта легко считывалась.',
      'После этого загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_passport_16_17',
  },
  passport_18_19: {
    header: 'passport',
    name: 'Страницы о  ранее выданных паспортах',
    description: 'Необходимо загрузить страницы №18, №19',
    formats: photoFormats,
    instruction: [
      'Разложите паспорт на плоскую поверхность так, чтобы все четыре угла были видны в кадре.',
      'Убедитесь, что освещение хорошее и нет бликов на паспорте.',
      'Сфотографируйте паспорт так, чтобы в кадре были видны все его углы, а информация с паспорта легко считывалась.',
      'После этого загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_passport_18_19',
  },
  temporary_registration: {
    header: 'registration',
    name: 'Временная регистрация',
    description: 'Загрузите фото временной регистрации',
    formats: ['pdf', ...photoFormats],
    instruction: [
      'Запросите документ из Госуслуг или в МФЦ.',
      'Прикрепите документ в формате .pdf или сфотографируйте его.',
      'Загрузите документ на сайт, следуя инструкциям на экране.',
    ],
    links: ['https://api.denginadom.ru/v1/file/1724416254557_.png'],
    event_name: 'files_temporary_registration',
  },
  second_document: {
    header: 'additional',
    name: 'Второй документ',
    description: 'Загрузите ваш второй документ',
    formats: ['pdf', ...photoFormats],
    event_name: 'files_second_document',
  },
  bank_card: {
    header: 'card',
    name: 'Банковская карта',
    description: 'Необходимо загрузить фото вашей банковской карты',
    formats: photoFormats,
    instruction: [
      'Сфотографируйте карту с хорошим освещением и без бликов.',
      'Убедитесь, что на фотографии четко видны номер карты, срок действия и имя владельца. ',
      'Загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_bank_card',
    links: [
      'https://api.denginadom.ru/v1/file/1729499758450_2024102111.35.35.jpg',
      'https://api.denginadom.ru/v1/file/1729499758453_2024102111.35.40.jpg',
      'https://api.denginadom.ru/v1/file/1729499758454_2024102111.35.45.jpg',
    ],
  },
  photo_with_passport: {
    header: 'passport',
    name: 'Загрузите ваше фото с паспортом',
    description: 'Необходимо загрузить ваше фото с паспортом в руке',
    formats: photoFormats,
    instruction: [
      'Подготовьте паспорт. Убедитесь, что все данные паспорта хорошо видны и читаемы.',
      'Возьмите смартфон или другое устройство для съемки фото. Убедитесь, что камера работает исправно и качество изображения хорошее.',
      'Выберите место с хорошим освещением, чтобы лицо на фото было хорошо видно.',
      'Запустите камеру в режиме съемки фото.',
      'Держите паспорт в одной руке и камеру в другой. Убедитесь, что страницы документа хорошо видны в кадре.',
      'Сделайте фотографию ',
      'Загрузите ваше фото на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_photo_with_passport',
  },
  photo_with_second_document: {
    header: 'additional',
    name: 'Фото со вторым документом',
    description: 'Загрузите фото со вторым документом в руке',
    formats: photoFormats,
    event_name: 'files_photo_with_second_document',
  },
  photo_with_bank_card: {
    header: 'card',
    name: 'Фото клиента с картой',
    description: 'Необходимо загрузить ваше фото с банковской картой в руке',
    formats: photoFormats,
    instruction: [
      'Подготовьте банковскую карту. Убедитесь, что все данные карты хорошо видны и читаемы.',
      'Возьмите смартфон или другое устройство для съемки фото. Убедитесь, что камера работает исправно и качество изображения хорошее.',
      'Выберите место с хорошим освещением, чтобы лицо на фото было хорошо видно.',
      'Запустите камеру в режиме съемки фото.',
      'Держите банковскую карту в одной руке и камеру в другой. Убедитесь, что номер карты хорошо видно в кадре.',
      'Сделайте фотографию ',
      'Загрузите ваше фото на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_photo_with_bank_card',
  },
  etk: {
    header: 'gosuslugi',
    name: 'ЭТК',
    description: 'Необходимо сделать скриншот документа из приложения Госуслуги',
    formats: ['pdf', ...photoFormats],
    instruction: [
      'Запросите документ из Госуслуг.',
      'Прикрепите полученный документ, не изменяя его.',
      'Загрузите документ на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_etk',
  },
  statement: {
    header: 'gosuslugi',
    name: 'Выписка',
    description: 'Необходимо сделать скриншот документа из приложения Госуслуги',
    formats: ['pdf', ...photoFormats],
    instruction: [
      'Запросите документ из Госуслуг.',
      'Прикрепите полученный документ, не изменяя его.',
      'Загрузите документ на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_statement',
  },
  paper_statement: {
    header: 'additional',
    name: 'Бумажная выписка',
    description: 'Необходимо загрузить бумажную выписку',
    formats: ['pdf', ...photoFormats],
    event_name: 'files_paper_statement',
  },
  pensioners_document: {
    header: 'pensioner',
    name: 'Пенсионное удостоверение',
    description: 'Загрузите необходимые страницы пенсионного удостоверения',
    formats: ['pdf', ...photoFormats],
    instruction: [
      'Сфотографируйте пенсионное удостоверение в развернутом виде с хорошим освещением и без бликов.',
      'Убедитесь, что на фотографии четко видно имя владельца. ',
      'Загрузите фотографию на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_pensioners_document',
  },
  reference_pfr: {
    header: 'gosuslugi',
    name: 'Справка ПФР',
    description: 'Необходимо сделать скриншот документа из приложения Госуслуги',
    formats: 'pdf',
    instruction: [
      'Запросите документ из Госуслуг.',
      'Прикрепите полученный документ, не изменяя его.',
      'Загрузите документ на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_reference_pfr',
  },
  service_document: {
    header: 'additional',
    name: 'Служебное удостоверение',
    description: 'Загрузите фото служебного удостоверения',
    formats: photoFormats,
    event_name: 'files_service_document',
  },
  document_fssp: {
    header: 'additional',
    name: 'Док-нт подтверждающий от-ие задолж. ФССП',
    description: 'Док-нт подтверждающий от-ие задолж. ФССП',
    formats: ['pdf', ...photoFormats],
    event_name: 'files_document_fssp',
  },
  screen_credit_organization: {
    header: 'additional',
    name: 'Док-нт подтверждающий от-ие задолж перед кред. орг.',
    description: 'Док-нт подтверждающий от-ие задолж перед кред. орг.',
    formats: photoFormats,
    event_name: 'files_screen_credit_organization',
  },
  screen_sms_salary: {
    header: 'additional',
    name: 'Скрин смс з/п',
    description: 'Скрин смс з/п',
    formats: photoFormats,
    event_name: 'files_screen_sms_salary',
  },
  screen_taxi: {
    header: 'additional',
    name: 'Скрин приложнеия таксиста',
    description: 'Скрин приложнеия таксиста',
    formats: photoFormats,
    event_name: 'files_screen_taxi',
  },
  screen_renewal_card: {
    header: 'additional',
    name: 'Фото о продление карты',
    description: 'Загрузите фото о продление карты',
    formats: photoFormats,
    instruction: [
      'Зайдите в приложение банка',
      'Сделайте скриншот из приложения. Убедитесь, что на скриншоте хорошо видно номер карты, срок действия и имя владельца. ',
      'Загрузите скриншот на сайт, следуя инструкциям на экране.',
    ],
    event_name: 'files_screen_renewal_card',
  },
  screen_sb: {
    header: 'additional',
    name: 'Скрин согласования СБ',
    description: 'Скрин согласования СБ',
    formats: photoFormats,
    event_name: 'files_screen_sb',
  },
  screen_vk: {
    header: 'additional',
    name: 'Cкрин страницы ВК',
    description: 'Cкрин страницы ВК',
    formats: photoFormats,
    event_name: 'files_screen_vk',
  },
  bank_online: {
    header: 'additional',
    name: 'Подтверждение карты онлайн банка',
    description: 'Загрузите скриншот подтверждение карты онлайн банка',
    formats: photoFormats,
    event_name: 'files_bank_online',
  },
  video: {
    header: 'video',
    name: 'Видео',
    description: 'Пожалуйста, ознакомьтесь с инструкцией, перед отправкой файла на проверку.',
    formats: videoFormats,
    instruction: [
      'Подготовьте паспорт. Убедитесь, что все данные на 2-3 странице хорошо видны и читаемы.',
      'Возьмите смартфон или другое устройство для съемки видео. Убедитесь, что камера работает исправно и качество изображения хорошее.',
      'Выберите место с хорошим освещением, чтобы лицо и паспорт полностью просматривались на видео.',
      'Запустите камеру в режиме съемки видео.',
      'Держите паспорт рядом с лицом в одной руке и камеру в другой. Убедитесь, что лицо и  2-3 страницы документа хорошо видны в кадре, четко и внятно, глядя в камеру, назовите свои фамилию, имя, отчество и номер телефона.',
      'Поднесите паспорт к камере так, чтобы были видны все данные 2-3 страницы (разворот с фотографией).',
      'Завершите съемку и проверьте записанное видео. Убедитесь, что все данные на видео четко видны и слышны.',
      'Загрузите ваше видео на сайт, следуя инструкциям на экране.',
    ],
    links: ['https://api.denginadom.ru/v1/file/1724655048079_img_0312.mov01.mp4'],
    event_name: 'files_video',
  },
  dogovor: {
    header: 'additional',
    name: 'Фото с договором',
    description: 'Фото с договором',
    formats: photoFormats,
    event_name: 'files_dogovor',
  },
  task: {
    header: 'additional',
    name: 'Задача',
    description: 'Задача',
    formats: photoFormats,
    event_name: 'files_task',
  },
  inoe: {
    header: 'additional',
    name: 'Иное',
    description: 'Иное',
    formats: photoFormats,
    event_name: 'files_inoe',
  },
  e_t_k_12: {
    header: 'additional',
    name: 'ЭБВ 12 мес.',
    description: 'Иное',
    formats: photoFormats,
    event_name: 'files_e_t_k_12',
  },
  selfie_with_phone_number: {
    header: 'additional',
    name: 'Селфи с номером телефона',
    description: 'Иное',
    formats: photoFormats,
    event_name: 'files_selfie_with_phone_number',
  },
  ebv_3_months: {
    header: 'additional',
    name: 'ЭБВ 3 мес.',
    description: 'Иное',
    formats: ['pdf', ...photoFormats],
    event_name: 'files_ebv_3_months',
  },
  insert_Tatarstan_Bashkortostan: {
    header: 'additional',
    name: 'Фото вкладыша паспорта Татарстана/Башкортостана',
    description: 'Иное',
    formats: photoFormats,
    event_name: 'files_insert_tatarstan_bashkortostan',
  },
};

export const useFilesUploadStore = defineStore(
  'uploadFiles',
  () => {
    const { $api } = useNuxtApp();

    const userStore = useUserStore();
    const notieStore = useNotieStore();

    const timeoutToRemoveFileAfterUpload = 500;
    const progress = ref(0);
    const filesObj = reactive({});
    const filesObjServer = reactive({});
    const step = ref(-1);
    const stepsTotal = ref(0);
    const filesLoadingServer = ref(false);

    const filesChange = (filesFormInput, tag) => {
      const boolVideo = tag === 'video';
      const invalidFilesNames = [];
      let dublicateFile = false;

      for (const file of filesFormInput) {
        for (const tag of Object.keys(filesObj)) {
          if (filesObj[tag].files.find((item) => item.name === file.name)) {
            dublicateFile = true;
            break;
          }
        }

        if (dublicateFile) {
          notieStore.addNotification({
            type: 'warning',
            message: `Файл ${compactFileName(file.name, 16)} уже добавлен!`,
          });

          return false;
        }

        const fileExtension = extensionsFile(file.name);

        if (boolVideo) {
          if (filesObj[tag]?.files.length === 1 || filesFormInput.length > 1) {
            notieStore.addNotification({
              type: 'warning',
              message: 'Вы можете добавить только один файл',
            });

            return false;
          }
          if (!videoFormats.includes(fileExtension)) {
            notieStore.addNotification({
              type: 'warning',
              message: 'Видео такого формата не поддерживается',
            });

            return false;
          }
          const videoLimitInMb = 2048;
          if (file.size / 1024 / 1024 > videoLimitInMb) {
            notieStore.addNotification({
              type: 'warning',
              message: 'Размер видео не должен превышать 2 Гб',
            });

            return false;
          }

          addNewFile(file, tag);
        }

        if (!boolVideo) {
          if (
            (tagsDenotationObj[tag].formats &&
              !tagsDenotationObj[tag].formats.includes(fileExtension)) ||
            (!tagsDenotationObj[tag].formats && documentsFormats.includes(fileExtension))
          ) {
            if (tagsDenotationObj?.[tag]) {
              invalidFilesNames.push(compactFileName(file.name));
            }
            continue;
          }

          const fileLimitInMb = 10;

          if (file.size / 1024 / 1024 > fileLimitInMb) {
            notieStore.addNotification({
              type: 'warning',
              message: 'Размер файла не должен превышать 10 Мб',
            });

            return false;
          }

          addNewFile(file, tag);
        }
      }

      if (invalidFilesNames.length) {
        notieStore.addNotification({
          type: 'warning',
          message: `Неподдерживаемые форматы файлов: ${invalidFilesNames.join(', ')}.  Поддерживаемые форматы: ${tagsDenotationObj[tag].formats}.`,
        });

        return false;
      }

      return true;
    };

    const addNewFile = (file, tag) => {
      if (tag in filesObj) {
        filesObj[tag].files.push(file);
      } else
        filesObj[tag] = {
          files: [file],
          progress: 0,
          upload: false,
          uploadSuccess: false,
        };
    };

    const deleteFile = (fileName, tag) => {
      filesObj[tag].files = filesObj[tag].files.filter((item) => item.name !== fileName);
      if (!filesObj[tag].files.length) {
        delete filesObj[tag];
      }
    };

    const compactFileName = (fileName, length = 10) => {
      const splitted = fileName.split('.');
      if (splitted.length <= 1) return fileName;
      const name = splitted[0];
      const ext = splitted.pop();
      if (!name || !ext) return name;

      return name.slice(0, length) + `...(${ext})`;
    };

    const extensionsFile = (name) => {
      return name.toLowerCase().split('.').pop();
    };

    const uploadFiles = async (isFinal) => {
      filesLoadingServer.value = true;
      const promises = [];
      try {
        for (const key of Object.keys(filesObj)) {
          if (
            !('files' in filesObj[key]) ||
            (Array.isArray(filesObj[key]?.files) && filesObj[key].files.length === 0)
          )
            continue;

          const formData = new FormData();
          filesObj[key].files.forEach((file) => {
            formData.append('multi-files', file);
          });

          const promise =
            key === 'video'
              ? $api.disk(key).uploadVideo(formData, userStore.user.contract_guid, isFinal)
              : $api.disk(key).uploadFiles(formData, userStore.user.contract_guid, isFinal);
          promises.push(promise);
        }

        await Promise.all(promises);

        setTimeout(() => {
          Object.keys(filesObj).forEach((key) => {
            delete filesObj[key];
          });
          notieStore.addNotification({
            type: 'success',
            message: 'Файлы успешно загружены',
          });
          if (isFinal) {
            step.value = -1;
          } else {
            step.value += 1;
          }
          filesLoadingServer.value = false;
        }, timeoutToRemoveFileAfterUpload);
      } catch (error) {
        throw new Error(error);
      }
    };

    // const getFiles = async () => {
    // const { data } = await $api.disk().getClientFiles(userStore.user.contract_guid);
    // data.data.forEach((f) => {
    //   if (f.tag in filesObjServer) {
    //     filesObjServer[f.tag].files.push(f);
    //   } else
    //     filesObjServer[f.tag] = {
    //       files: [f],
    //       uploadSuccess: true,
    //     };
    // });
    // };

    return {
      filesChange,
      uploadFiles,
      deleteFile,
      compactFileName,
      extensionsFile,
      filesObj,
      timeoutToRemoveFileAfterUpload,
      tagsDenotationObj,
      progress,
      step,
      stepsTotal,
      filesObjServer,
      filesLoadingServer,
    };
  },
  {
    persist: false,
  },
);
